import useSWR from 'swr/immutable'
import axios from 'axios'
import { SailPackage } from '@/root/shared-types'

interface GetSailPackageByIdProps {
  locale: string
  code: string
}

export const useGetSailPackageById = ({ locale, code }: GetSailPackageByIdProps): SailPackage | null => {
  const getPackage = async (): Promise<SailPackage | null> => {
    if (!locale) return null
    const { data: packages } = await axios.get<SailPackage[]>('/api/get-packages/sailPackages', {
      params: { locale },
    })

    return packages?.find((pkg) => pkg.code === code) || null
  }

  const { data: packageById } = useSWR<SailPackage | null>(locale ? `get-packages-${locale}-${code}` : null, getPackage)

  return packageById ?? null
}
