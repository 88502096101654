import React, { FC } from 'react'
import cn from 'classnames'
import { ChevronDownIcon } from '@/root/ui/shared/icons/ChevronDownIcon'

interface DayPickerTitleProps {
  title: string
}

const TITLE_LENGTH = 25

const getMaxLength = (arr: string[]) => {
  if (arr.length === 0) return 0
  const length_0 = arr[0]?.length || 0
  const length_1 = arr[1]?.length || 0
  return Math.max(length_0, length_1)
}

export const DayPickerTitle: FC<DayPickerTitleProps> = ({ title }) => {
  const titleArr = title?.split(' - ') || []
  const isLongTitle = getMaxLength(titleArr) > TITLE_LENGTH

  return (
    <div
      className={cn(
        { 'grid grid-cols-[1fr_auto_1fr]': Boolean(titleArr[1]) },
        'items-center text-[23px] gap-4 p-4 font-bold leading-relaxed'
      )}
    >
      <p className={cn('ps-1 text-center gap-4 ltr:line-clamp-2 rtl:line-clamp-3', { 'rtl:text-xl': isLongTitle })}>
        {titleArr[0]}
      </p>
      {titleArr[1] && (
        <>
          <ChevronDownIcon width={15} height={15} color="primary-base" className="-rotate-90" ignoreRtl />
          <p className={cn('pe-1 text-center ltr:line-clamp-2 rtl:line-clamp-3', { 'rtl:text-xl': isLongTitle })}>
            {titleArr[1]}
          </p>
        </>
      )}
    </div>
  )
}
