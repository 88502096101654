import React, { FC, PropsWithChildren, useState } from 'react'
import { SailPackageContext } from './SailPackageContext'
import { SailPackage } from '@/root/shared-types'

export const SailPackageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [sailPackage, setSailPackage] = useState<SailPackage>()

  const contextValue = {
    sailPackage,
    setSailPackage,
  }

  return <SailPackageContext.Provider value={contextValue }>{children}</SailPackageContext.Provider>
}
