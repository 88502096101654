import { useMemo } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import * as R from 'ramda'

export const useTravelDurationMessage = (durationInMinutes: string) => {
  const { getTranslation } = useGetTranslations()

  return useMemo(() => {
    if (!durationInMinutes) return ''

    const minutes = parseInt(durationInMinutes, 10)

    if (Number.isNaN(minutes) || minutes <= 0) return ''

    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60

    const getDurationMessage = R.cond([
      [
        R.both(R.gt(R.__, 0), R.always(hours > 0 && remainingMinutes > 0)),
        R.always(getTranslation('durationWithHoursAndMinutes', { hours, minutes: remainingMinutes })),
      ],
      [R.always(hours > 0), R.always(getTranslation('durationOnlyWithHours', { hours }))],
      [R.T, R.always(getTranslation('durationOnlyWithMinutes', { minutes: remainingMinutes }))],
    ])

    return getDurationMessage(minutes)
  }, [durationInMinutes, getTranslation])
}
