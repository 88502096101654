import useSWR from 'swr/immutable'
import axios from 'axios'
import { DateTime } from 'luxon'
import { dateFormats } from '@/root/shared-consts'

interface UseGetAvailableDatesProps {
  route?: string
}

export const useGetAvailableDates = ({ route }: UseGetAvailableDatesProps): Array<Date> => {
  const today = DateTime.now().toFormat(dateFormats.ISO_DATE)
  const getDates = async (): Promise<Array<string> | null> => {
    if (!route) return []
    const { data } = await axios({
      url: `/api/${today}/${route}`,
    })
    return data
  }

  const { data } = useSWR(route ? `get-dates ${route} ${today}` : null, getDates)

  return data ? data.map((date) => new Date(date)) : []
}
