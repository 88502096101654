import { axios } from '@/configs'

import { Locale, PreparePaymentRequestBody, Reservation, Sails } from '@/root/shared-types'
import { AxiosInstance, AxiosResponse } from 'axios'
import { StartNewEmptyReservation } from '@/root/shared-types/dto/Modifications'
import { Owner } from '@/root/shared-types/companyInfoPage/company-info-types'
import { RequestDTO } from '@/root/shared-providers/BookingCart/types'

const API_V3_RESERVATION_PATH = '/api/v3/reservation'

class ReservationService {
  async getReservation(token: string, locale: string) {
    const { data: reservation } = await axios<Reservation>({
      url: `reservation/token/${token}`,
      method: 'get',
      headers: { 'Accept-Language': locale },
    })
    return reservation
  }

  async refundReservation(reservationId: number) {
    const { data: reservation } = await axios<Reservation>({
      url: `reservation/${reservationId}/refund`,
      method: 'post',
    })
    return reservation
  }

  async refundReservationSails(reservationId: number, sailRefIds: number[]) {
    const res = await axios<Sails>({
      url: `reservation/${reservationId}/refund/sails`,
      method: 'post',
      data: { sailRefIds },
    })
    const { data: sails, status } = res
    return { sails, status }
  }

  async createNewEmptyReservation(axiosInstance: AxiosInstance, locale: Locale) {
    const { data: emptyReservation } = await axiosInstance.post<
      Reservation,
      AxiosResponse<Reservation>,
      StartNewEmptyReservation
    >(API_V3_RESERVATION_PATH, {}, { headers: { 'Accept-Language': locale } })

    return emptyReservation
  }

  async createNewReservation(axiosInstance: AxiosInstance, locale: Locale, body: RequestDTO) {
    const { data: emptyReservation } = await axiosInstance.post<Reservation, AxiosResponse<Reservation>, RequestDTO>(
      API_V3_RESERVATION_PATH,
      body,
      { headers: { 'Accept-Language': locale } }
    )
    return emptyReservation
  }

  async updateReservationTickets(
    axiosInstance: AxiosInstance,
    reservationId: string,
    locale: Locale,
    body: RequestDTO
  ) {
    const { data: reservation } = await axiosInstance.post<Reservation, AxiosResponse<Reservation>, RequestDTO>(
      `${API_V3_RESERVATION_PATH}/${reservationId}`,
      body,
      { headers: { 'Accept-Language': locale } }
    )
    return reservation
  }

  async confirm(axiosInstance: AxiosInstance, reservationId: string, locale: Locale) {
    const { data: reservation } = await axiosInstance.post<Reservation, AxiosResponse<Reservation>, null>(
      `${API_V3_RESERVATION_PATH}/${reservationId}/confirm`,
      null,
      { headers: { 'Accept-Language': locale } }
    )
    return reservation
  }

  async postReservationOwner(reservationId: number, owner: Owner) {
    try {
      const { data } = await axios.put(`/set-reservation-owner/${reservationId}`, owner)
      return { success: true, data }
    } catch (error) {
      return { success: false, error }
    }
  }
  async preparePayment(body: PreparePaymentRequestBody) {
    const { data } = await axios.put(`prepare-payment`, body)
    if (data) {
      window.location.href = data.redirectUrl
    }
  }
}

const reservationService = new ReservationService()

export default reservationService
