import '@/pages/globals.css'
import { Providers } from '@/root/shared-providers'
import Head from 'next/head'

import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import type { AvailableTheme } from '@/lang'
import { BoraTheme } from '@/root/shared-types'
import { apiAxios, axios } from '@/configs'
import { AppConfig } from '@/root/shared-types/app-config/app-config'

const getConfig = async (): Promise<AppConfig> => {
  const { data } = await axios<AppConfig>({
    url: `app-config`,
    method: 'get',
  })
  return data
}

const theme = process.env.NEXT_PUBLIC_THEME as AvailableTheme

type AppOwnProps = { appConfig: AppConfig }

export default function Sales2App({ Component, pageProps, appConfig }: AppProps & AppOwnProps) {
  const favIconPath: Record<BoraTheme, string> = {
    bluewave: '/bluewave/icons/faviconBluewave.ico',
    admaritime: '/admaritime/icons/faviconAdmaritime.ico',
  }

  return (
    <>
      <Head>
        <link rel="shortcut icon" href={favIconPath[theme]} />
      </Head>
      <Providers theme={theme} appConfig={appConfig}>
        <Component {...pageProps} />
      </Providers>
    </>
  )
}

Sales2App.getInitialProps = async (appContext: AppContext) => {
  // https://nextjs.org/docs/pages/building-your-application/routing/custom-app
  const appProps = await App.getInitialProps(appContext)

  if (typeof window === 'undefined') {
    try {
      const { data } = await apiAxios.get<AppConfig>(`/api/v1/config`)
      return { ...appProps, ...{ appConfig: data } }
    } catch (e) {
      return { ...appProps }
    }
  } else {
    try {
      const appConfig = await getConfig()
      return { ...appProps, ...{ appConfig } }
    } catch (e) {
      return { ...appProps }
    }
  }
}
