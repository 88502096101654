import React, { FC } from 'react'
import { BidirectionIcon } from '@/root/ui/shared/icons/BidirectionIcon'
import { WaterTaxiIcon } from '@/root/ui/shared/icons/WaterTaxiIcon'
import { PassengerIconCyan } from '@/root/ui/shared/icons/PassengerIconCyan'
import { CarIcon } from '@/root/ui/shared/icons/CarIcon'
import { TruckIconCyan } from '@/root/ui/shared/icons/TruckIconCyan'

interface RouteInfoProps {
  locationFrom: string
  locationTo: string
  isTaxi: boolean
  containsTrucks: boolean
  containsCars: boolean
  containsPassengers: boolean
}

const RouteInfo: FC<RouteInfoProps> = ({
  locationFrom,
  locationTo,
  isTaxi,
  containsTrucks,
  containsPassengers,
  containsCars,
}) => {
  return (
    <div className="flex basis-1/2 flex-col items-center justify-evenly text-base font-bold">
      <div>{locationFrom} </div>
      <BidirectionIcon />
      <div>{locationTo} </div>

      <div className="flex h-[30px] items-center justify-center gap-[14px]">
        {isTaxi ? (
          <WaterTaxiIcon />
        ) : (
          <>
            {containsPassengers && <PassengerIconCyan width={10} />}
            {containsCars && <CarIcon width={25} />}
            {containsTrucks && <TruckIconCyan />}
          </>
        )}
      </div>
    </div>
  )
}

export default RouteInfo
